import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const InmodeResourceCenter = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const fadeInVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className='w-full relative'>
      <StaticImage src="../../assets/images/inmode-estore/Resource Center Images.png" alt="Resource Center Images" />
      <div className='absolute w-full h-full top-0 left-0 flex flex-row items-start'>
        <div className='w-1/2'></div>
        <div className='w-1/2 bg-[#9EABBF] px-[30px] py-[25px]'>
          <ol className='list-decimal text-black text-[16px] font-bold font-poppins mb-[30px]'>
            {[
              'Managing customer account requests',
              'Resending custom transactional customer emails',
              'Managing discount code SKUs',
              'Managing who receives emails about specific events',
              'Exporting orders in custom CSV formats',
              'Managing gift card reasons and SKUs (custom metadata)',
              'Viewing existing gift cards and their custom metadata',
              'Issuing gift cards with custom metadata',
              'Managing pending orders',
              'Managing product and collection permissions',
              'Managing sales representative order limits',
              'Managing single order limits',
              'Managing staff access within the InMode custom app'
            ].map((item, index) => (
              <motion.li
                key={index}
                className='leading-[54px]'
                ref={ref}
                variants={fadeInVariants}
                initial="hidden"
                animate={inView ? "visible" : "hidden"}
                transition={{ duration: 1.5, delay: index * 0.1 }}
              >
                {item}
              </motion.li>
            ))}
          </ol>
          <div className='w-full flex items-center justify-center mb-[45px]'>
            <StaticImage className="w-full max-w-[180px] h-[22px]" src="../../assets/images/inmode-estore/inmode-logo.png" alt="Inmode Logo" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default InmodeResourceCenter;
