import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const InmodeHandling = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div className='w-full relative mb-[140px]' ref={ref}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: inView ? 1 : 0 }}
        transition={{ duration: 1 }}
      >
        <StaticImage
          className="w-full"
          src='../../assets/images/inmode-estore/inmode-phones 1.png'
          alt="phones"
        />
      </motion.div>
      <div className='max-w-[1196px] w-full h-full ml-auto mr-auto absolute top-1/2 left-1/2 transform-centered'>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: inView ? 1 : 0 }}
          transition={{ duration: 1, delay: 0.5 }}
          className='max-w-[500px] w-full ml-auto mr-auto px-4 absolute bottom-0 right-0'
        >
          <div className='w-full mb-[32px]'>
            <h2 className='text-[36px] font-montserrat font-bold leading-normal text-black'>Other Handling</h2>
          </div>
          <div className='w-full mb-[40px]'>
            <p className='font-poppins text-black text-[16px] font-normal leading-[22.4px] mb-[20px]'>
              InMode’s order handling system is a custom solution that listens for CSV files uploaded to a server. We do this via a Shopify webhook for new orders which sends the order information to our backend where it is transformed into the necessary CSV format and uploaded to the server via SFTP.
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default InmodeHandling;
