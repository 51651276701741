import React, { useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const InmodeImageText = ({ position = 'left', header, body }) => {
    const controls = useAnimation();
    const [ref, inView] = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1, transition: { duration: 1 } });
    }
  }, [controls, inView]);

  const isImageLeft = position === 'left';

  return (
      <div className='w-full flex flex-row items-center mb-[135px]'>
        {isImageLeft && (
          <div className='w-1/2'>
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={controls}
            >
              <StaticImage src="../../assets/images/inmode-estore/inmode-model-image.png" alt='Scroll content image' />
            </motion.div>
          </div>
        )}
        <div className={`w-1/2 ${isImageLeft ? 'pl-[20px]' : 'pr-[20px]'}`}>
          <h2 className='text-[36px] font-montserrat font-bold leading-normal text-black mb-[39px]'>{header}</h2>
          <p className='font-poppins text-black text-[16px] font-normal leading-[22.4px]'>
            {body}
          </p>
        </div>
        {!isImageLeft && (
          <div className='w-1/2'>
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={controls}
            >
              <StaticImage src="../../assets/images/inmode-estore/inmode-model-image.png" alt='Scroll content image' />
            </motion.div>
          </div>
        )}
      </div>
  );
};

export default InmodeImageText;
