import React, { useRef, useState, useEffect } from 'react';

const InmodeReverseScrollVideo = ({ videoSrc }) => {
  const videoRef = useRef(null);
  const [scrollY, setScrollY] = useState(0);
  const totalFrames = 375; // Change this to the number of frames in your video
  const duration = 10; // Duration of your video in seconds

  const handleScroll = () => {
    const scrollTop = window.scrollY;
    const docHeight = document.documentElement.scrollHeight - window.innerHeight;
    const scrollFraction = scrollTop / docHeight;
    setScrollY(scrollFraction * 100);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      const newFrame = totalFrames - Math.round((scrollY / 100) * totalFrames);
      const newTime = (newFrame / totalFrames) * duration;
      videoRef.current.currentTime = newTime;
    }
  }, [scrollY]);

  return (
    <div style={{ height: '100vh' }}>
      <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'sticky', top: 0 }}>
        <video
          ref={videoRef}
          width="100%"
          height="auto"
          src={videoSrc}
          preload="auto"
          playsInline
          muted
        />
      </div>
    </div>
  );
};

export default InmodeReverseScrollVideo;
