import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const Card = ({ title, description, index }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  const variants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={variants}
      transition={{ duration: 0.5, delay: index * 0.3 }}
      className='w-[344px] h-[344px] bg-black rounded-[30px] flex flex-col items-start px-[20px] py-[40px] mb-8'
    >
      <h4 className='font-montserrat text-[28px] text-white font-medium mb-[40px]'>{title}</h4>
      <div className='w-[50px] h-[3px] bg-white mb-[30px]'></div>
      <p
        className='font-poppins text-[24px] leading-[33.6px] text-white'
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </motion.div>
  );
};


const InmodeCardSection = () => {
    const cards = [
        {
          title: '1. Web Store',
          description: 'Permissioned<br/>web store for<br/>approved customers.',
        },
        {
          title: '2. Resource Center',
          description: 'Permissioned website<br/>for viewing and<br/>downloading files for<br/>approved customers.',
        },
        {
          title: '3. Shopify App',
          description: 'For specific<br/>administration tasks.',
        },
      ];
      
  
    return (
      <div className='w-full grid grid-cols-1 md:grid-cols-3 items-center mb-[162px]'>
        {cards.map((card, index) => (
          <Card key={index} title={card.title} description={card.description} index={index} />
        ))}
      </div>
    );
  };
  
  export default InmodeCardSection;
  