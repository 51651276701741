import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const InmodeNeedsForWebsite = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  useEffect(() => {
    if (inView) {
      controls.start((i) => ({
        opacity: 1,
        y: 0,
        transition: {
          delay: i * 0.3,
          duration: 0.5,
        },
      }));
    }
  }, [controls, inView]);

  const items = [
    'Access only to approved users.',
    'Limited product access.',
    'Purchase limits.',
    'Order Approvals.',
  ];

  const fadeInVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.3,
        duration: 0.5,
      },
    }),
  };

  return (
    <div className='w-full max-w-[900px] ml-auto mr-auto mb-[150px]'>
      <div ref={ref} className='w-full ml-auto mr-auto max-w-[650px] flex flex-col items-start'>
        <h4 className='font-montserrat text-[24px] font-medium leading-normal text-[#07A6A2] mb-[42px]'>
          Needs for Website
        </h4>
        {items.map((item, index) => (
          <motion.h6
            key={index}
            custom={index}
            initial="hidden"
            animate={controls}
            variants={fadeInVariants}
            className='font-montserrat text-[36px] font-bold leading-normal text-[#07A6A2] mb-[42px]'
          >
            {item}
          </motion.h6>
        ))}
      </div>
    </div>
  );
};

export default InmodeNeedsForWebsite;
