import React from 'react';
import Layout from '../layouts';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

//assets
import inmode_video from "../assets/videos/inmode-page.mp4";
import inmode_sd from "../assets/videos/inmode-scroll-down.mp4";
import inmode_video_poster from "../assets/images/video-posters/inmode-video-1.png";
import InmodeCardSection from '../components/inmode-card-section';
import InmodeNeedsForWebsite from '../components/inmode-needs-for-website';
import InmodeResourceCenter from '../components/inmode-resource-center';
import '../layouts/styles/inmode/style.css';
import download_proxy_video from "../assets/videos/download-proxy.mp4";
import download_proxy_poster from "../assets/images/video-posters/download-proxy-poster.png";
import InmodeScrollVideo from '../components/inmode-scroll-video';
import InmodeReverseScrollVideo from '../components/inmode-reverse-scroll-video';
import InmodeImageText from '../components/inmode-image-text';
import InmodeHandling from '../components/inmode-handling';

const testNew = () => {
  return (
    <Layout>
        <div className='max-w-[1196px] w-full ml-auto mr-auto px-4'>
            <div className='w-full max-w-[605px]'>
                <h1 className='font-montserrat text-[128px] uppercase text-dark-blue mb-[40px]'>InMode Estore</h1>
            </div>

            <div className='w-full mb-[100px]'>
                <p className='font-poppins text-[32px] font-semibold leading-[44.8px]'>InMode is the leading global provider of medical aesthetic devices harnessing novel radiofrequency, light and laser-based technologies. They operate globally providing their technologies and products to clinics.</p>
            </div>

            <div className='w-full mb-[115px]'>
                <video autoPlay loop muted poster={inmode_video_poster} className='w-full ml-auto mr-auto'>
                    <source src={inmode_video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>

            <div className='w-full mb-[115px]'>
                <p className='font-poppins text-black text-[16px] font-normal leading-[22.4px]'>We facilitated the transition from their existing setup on Wordpress to Shopify for both a more scalable admin experience and also a more enjoyable experience for clinics.</p>
            </div>

            {/* this part needs to be worked on a bit more in terms of the gif and working with scroll */}
            <div className='flex flex-row items-center mb-[150px]'>
                <div className='w-1/4'>
                    <InmodeScrollVideo videoSrc={inmode_sd} />
                </div>
                <div className='w-1/4'>
                    <InmodeReverseScrollVideo videoSrc={inmode_sd}/>
                </div>
                <div className='w-1/4'>
                    <InmodeScrollVideo videoSrc={inmode_sd} />
                </div>
                <div className='w-1/4'>
                    <InmodeReverseScrollVideo videoSrc={inmode_sd} />
                </div>
            </div>

            <div className='w-full max-w-[824px] mb-[127px]'>
                <h2 className='text-[36px] font-montserrat font-bold leading-normal text-black '>The Main Requirements</h2>
            </div>


            {/* Grid content to come in one by one on scroll */}
            <InmodeCardSection/>

            <div className='w-full mb-[39px]'>
                <h2 className='text-[36px] font-montserrat font-bold leading-normal text-black '>The Web Store</h2>
            </div>
            <div className='w-full mb-[100px]'>
                <p className='font-poppins text-black text-[16px] font-normal leading-[22.4px] mb-[20px]'>By default, Shopify fulfills the standard needs of any web store. However, there are still a number of requirements which it doesn’t fulfill, some of which are surprisingly common. We spent quite some time researching and vetting the abundance of apps which are available in the Shopify app store to see how they could be combined to suit InMode’s requirements. However, due to the scale of InMode’s requirements we ultimately decided to create custom solutions which would work seamlessly with one another.</p>
                <p className='font-poppins text-black text-[16px] font-normal leading-[22.4px] mb-[20px]'>We also explored Shopify’s wholesale sales channel which is available to Shopify Plus merchants, however we became quickly aware that it was extremely basic and would not fulfill the extensive requirements of InMode, even with our own custom solutions built on top of it.</p>
                <p className='font-poppins text-black text-[16px] font-normal leading-[22.4px] mb-[20px]'>Instead, we opted to use Shopify’s regular online store sales channel with a custom theme built from scratch to facilitate all of InMode’s requirements such as their in depth permissions system.</p>
            </div>

            <InmodeNeedsForWebsite/>

            <div className='w-full mb-[39px]'>
                <h2 className='text-[36px] font-montserrat font-bold leading-normal text-black '>Search</h2>
            </div>
            <div className='w-full flex items-center justify-center mb-[84px]'>
                <StaticImage alt="Inmode Nav bar" src='../assets/images/inmode-estore/Resource Center Nav Bar 1.png'/>
            </div>
            <div className='w-full mb-[100px]'>
                <p className='font-poppins text-black text-[16px] font-normal leading-[22.4px] mb-[20px]'>As you might expect, Shopify’s out of the box search functionality did not have the customization necessary for facilitating the permissioned product and resource search required by InMode. This is why we opted to use Algolia instead.</p>
            </div>

            <div className='w-full flex flex-row items-center'>
                <div className='w-1/2 pr-[20px]'>
                    <h2 className='text-[36px] font-montserrat font-bold leading-normal text-black mb-[39px]'>The Resource Center</h2>
                    <p className='font-poppins text-black text-[16px] font-normal leading-[22.4px]'>We opted to use Contentful, a CMS (content management system), which we have extensive experience with via our other clients. Contentful allows us to declare content models with specific fields and validations which can then be managed by InMode staff. Additionally, Contentful hosts all the files for all the resources.</p>
                </div>
                <div className='w-1/2'>
                    <StaticImage src="../assets/images/inmode-estore/devices.png" alt='inmode devices'/>
                </div>
            </div>

            <div className='w-full flex flex-row items-center mb-[120px]'>
                <div className='w-1/2 h-[459px] flex items-center justify-center relative bg-black'>
                    <StaticImage className="fading-image1" src="../assets/images/inmode-estore/contentful-logo.png" alt="Contentful Logo"/>
                    <StaticImage className="fading-image2" src="../assets/images/inmode-estore/gatsby-logo.png" alt="Gatsby Logo"/>
                </div>
                <div className='w-1/2 pl-[20px]'>
                    <h2 className='text-[36px] font-montserrat font-bold leading-normal text-black mb-[39px]'>Contentful & Gatsby JS</h2>
                    <p className='font-poppins text-black text-[16px] font-normal leading-[22.4px] mb-[20px]'>We decided to manage all of the global data in Contentful since the Resource Center is shared between all of the stores. This means that any changes are immediately available to all stores without needing to maintain multiple sources 
                    of truth.</p>
                    <p className='font-poppins text-black text-[16px] font-normal leading-[22.4px]'>We also used the JavaScript framework Gatsby JS to build static pages of all of the resources since Contentful’s delivery API limits were too low to handle the expected web traffic from InMode’s customers.</p>
                </div>
            </div>

            <div className='w-full mb-[118px]'>
                <h2 className='text-[36px] font-montserrat font-bold leading-normal text-black '>User Permissions</h2>
            </div>

            <div className='w-full flex flex-row items-ceneter mb-[89px]'>
                <div className='w-1/2'>
                <StaticImage src="../assets/images/inmode-estore/Frame 80.png" alt="Phone scrolling"/>
                </div>
                <div className='w-1/2'>
                <StaticImage src="../assets/images/inmode-estore/Frame 74 (1).png" alt="Inmode model"/>
                </div>
            </div>

            <div className='w-full mb-[115px]'>
                <p className='font-poppins text-black text-[16px] font-normal leading-[22.4px] mb-[20px]'>Before any of the page is rendered the top level window’s domain is checked and if it is not a valid InMode URL then the website either closes itself, returns to the previous page, or redirects to a blank page. Secondly, the markup of the page is also not rendered until after a valid connection with the InMode web store is made.</p>
            </div>

            <div className='w-full mb-[95px]'>
                <h2 className='text-[36px] font-montserrat font-bold leading-normal text-black '>Download Proxy</h2>
            </div>
            <div className='w-full mb-[150px]'>
                <video autoPlay loop muted poster={download_proxy_poster} className='w-full ml-auto mr-auto'>
                    <source src={download_proxy_video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>

            <div className='w-full mb-[40px]'>
                <h2 className='text-[36px] font-montserrat font-bold leading-normal text-black '>The Custom App</h2>
            </div>
            <div className='w-full mb-[150px]'>
                <p className='font-poppins text-black text-[16px] font-normal leading-[22.4px] mb-[20px]'>All of the custom functionality we set up for InMode needed to be managed somewhere. To keep the staff member experience as seamless as possible, we built InMode a custom Shopify app that is accessible in their Shopify admin dashboard. This app is where staff members complete admin tasks such as:</p>
            </div>

            <InmodeResourceCenter/>

            <div className='w-full mb-[40px]'>
                <h2 className='text-[36px] font-montserrat font-bold leading-normal text-black '>Staff Member Authetication</h2>
            </div>
            <div className='w-full mb-[100px]'>
                <p className='font-poppins text-black text-[16px] font-normal leading-[22.4px] mb-[20px]'>We implemented a rudimentary authentication system. We could have implemented a fully fledged authentication solution however this would have added friction to the staff member experience since they would have to log in a second time within the app. Instead, InMode was happy with a simple PIN code system, similar to how point of sale systems in restaurants or cafes work.</p>
            </div>

            <InmodeImageText position="right" header="Customer Applications" body="Staff members can approve or deny pending customer applications within the custom app. During the approval process staff members are required to select the necessary permissions the customer account should have. Upon approval the account is created and an email sent to the customer notifying them."/>

            <div className='w-full mb-[40px]'>
                <h2 className='text-[36px] font-montserrat font-bold leading-normal text-black '>Collection and product permissions</h2>
            </div>
            <div className='w-full mb-[100px]'>
                <p className='font-poppins text-black text-[16px] font-normal leading-[22.4px] mb-[20px]'>To attach permission sets from Contentful to collections and products in Shopify we are using metafields. All available permission sets are stored on each Shopify store via a metafield which is used to display the checklist to the staff member currently updating the permissions.</p>
            </div>

            <div className='w-full mb-[40px]'>
                <h2 className='text-[36px] font-montserrat font-bold leading-normal text-black '>Gift Cards</h2>
            </div>
            <div className='w-full mb-[100px]'>
                <p className='font-poppins text-black text-[16px] font-normal leading-[22.4px] mb-[20px]'>Most objects in Shopify can have metafields, which are custom values that can be attached to them for any purpose. However gift cards are one of the few objects that do not have metafields, much to the disappointment of InMode as they keep track of a number of metadata for every gift card that is issued.</p>
            </div>   
            
        </div>
        {/* <div className='w-full relative mb-[140px]'>
            <StaticImage className="w-full" src='../assets/images/inmode-estore/inmode-phones 1.png' alt="phones"/>
            
            <div className='max-w-[1196px] w-full h-full ml-auto mr-auto absolute top-1/2 left-1/2 transform-centered'>
                <div className='max-w-[500px] w-full ml-auto mr-auto px-4 absolute bottom-0 right-0'>
                <div className='w-full mb-[32px]'>
                        <h2 className='text-[36px] font-montserrat font-bold leading-normal text-black '>Other Handling</h2>
                    </div>
                    <div className='w-full mb-[40px]'>
                        <p className='font-poppins text-black text-[16px] font-normal leading-[22.4px] mb-[20px]'>InMode’s order handling system is a custom solution that listens for CSV files uploaded to a server. We do this via a Shopify webhook for new orders which sends the order information to our backend where it is transformed into the necessary CSV format and uploaded to the server via SFTP.</p>
                    </div>
                </div>
            </div>
        </div> */}
        <InmodeHandling />
        <div className='max-w-[1196px] w-full ml-auto mr-auto px-4'>
            <div className='w-full mb-[40px]'>
                <h2 className='text-[36px] font-montserrat font-bold leading-normal text-black '>Data Syncing</h2>
            </div>
            <div className='w-full mb-[100px]'>
                <p className='font-poppins text-black text-[16px] font-normal leading-[22.4px] mb-[20px]'>The backend of the custom app is also the receiver of a couple of webhooks from both Shopify and Contentful when products and resources are created, updated, or deleted. The handler of these webhooks syncs this data with Algolia.</p>
            </div>

            <div className='w-full mb-[40px]'>
                <h2 className='text-[36px] font-montserrat font-bold leading-normal text-black '>Conclusion</h2>
            </div>
            <div className='w-full mb-[100px]'>
                <p className='font-poppins text-black text-[16px] font-normal leading-[22.4px] mb-[20px]'>Using a handful of technologies together we have been able to add significant depth to Shopify’s core features where InMode required them most while simultaneously keeping software costs minimal and scalability easy. Our approach of breaking down their requirements into three distinct parts allows us to easily delegate requested features to their respective application.</p>
            </div>

        </div>
        <div className='w-full relative'>
            <StaticImage className="w-full" src='../assets/images/inmode-estore/banner-contact-us.png' alt="contact us banner"/>
            
            <div className='max-w-[1196px] w-full h-full ml-auto mr-auto absolute top-1/2 left-1/2 transform-centered flex flex-col justify-center'>
                <h2 className='text-[36px] font-montserrat font-bold leading-normal text-white '>Are you ready to scale your eComm brand?</h2>
                <h2 className='text-[36px] font-montserrat font-bold leading-normal text-white '>Get in touch with us!</h2>
                <Link to="/contact-us"><button className='hire_button'>Let's Chat</button></Link>
            </div>
        </div>
    </Layout>
  )
}

export default testNew;